import Appointment from './appointment';

export default class GuestAppointment extends Appointment {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        undefined: GuestAppointment;
    }
}
