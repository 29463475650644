import Controller from '@ember/controller';

export default class AppointmenSgt3Controller extends Controller {
    queryParams = ['token'];
    token = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        undefined: AppointmenSgt3Controller;
    }
}
