import Route from '@ember/routing/route';
import Context from '../../models/context';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';

interface ContextModel {
    context: Promise<Context>;
}

/**
 * @classdesc
 * Route for viewing and managing existing Self-Guided Tours appointments.
 */
export default class AppointmentSgt3Route extends Route {
    @service declare store: ServiceRegistry['store'];
    @service declare router: ServiceRegistry['router'];

    /**
     * @param model
     * @returns {{appointments: (model.appointment|Promise), context: (models.context|promise)}}
     */
    async model(params: { token: string }) {
        try {
            const contextModel = this.modelFor('context') as ContextModel;
            const context = await contextModel.context;
            const appointment = this.store.queryRecord(
                'guest-appointment',
                params
            );

            return {
                appointment,
                context
            };
        } catch (error) {
            this.send('error', error);

            return {};
        }
    }

    /**
     * @param model
     * redirects to not-found page if context does not have SGT3 toggle.
     */
    async afterModel(model: { context: Context }) {
        if (!model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
